@use "./material";
@use "./bootstrap";
@use "shopiagoDesign" as shopiago;

@include material.material;
@include bootstrap.bootstrap;
@include shopiago.font-face($selector: OpenSans, $name: OpenSans);
@include shopiago.font-face($selector: OpenSansBold, $name: OpenSansBold);
@include shopiago.font-face($selector: WorkSans, $name: WorkSans);
@include shopiago.common;
