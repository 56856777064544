@mixin variables {
  :root {
    --black: #000000;
    --blue: #71cbf4;
    --blue2: #dbf2fc;
    --blue3: #97caf2;
    --blue4: #71cbf440;
    --darkBlue: #4d5d76;
    --darkGray: #878787;
    --darkGray2: #87878740;
    --darkCyanBlue: #31425c;
    --darkCyan: #096f8a;
    --cyan: #97cbc0;
    --cyan2: #5c9a9b;
    --cyan3: #89cbc0;
    --gray: #4a4a49;
    --gray2: #d6d8d5;
    --pink: #ca4369;
    --pink2: #ad4968;
    --grayishBlue: #666a73;
    --orange: #fdc652;
    --yellow: #f5e3ac;
    --mostlyWhite: #f0f0f0;
    --lightGray: #f1f1f5;
    --lightGray2: #dfdfdf;
    --lightGray3: #e5e5e5;
    --lightGray4: #ebedee;
    --lightGray5: #f1f3f5;
    --lightGray6: #f6f6f6;
    --lightGray7: #d6d6d6;
    --lightGray8: #c0c0c0;
    --lightGray9: #f9f9f9;
    --lightGray10: #eeeeee;
    --lightGray11: #e1e1e1;
    --lightGray12: #c3c3c3;
    --lightGray13: #9ab9b2;
    --lightGray14: #cbcbcb;
    --lightGray15: #d8d8d8;
    --lightGray16: #e8e8e8;
    --lightGray17: #f3f3f3;
    --red: #f28f87;
    --red2: #f28f8740;
    --white: #ffffff;
    --purple: #838cc7;
    --cardBlockRadius: 8px;
    --cardBlockRadius2: 16px;
    --cardBlockRadius3: 4px;
    --inputHeight: 46px;
    --welcomeImage: url("/assets/ecommerce-images/welcomeImage.webp");
    --dialogBackground: rgba(0, 0, 0, 0.3);
    --mdc-checkbox-state-layer-size: 40px;
  }
}
