$fontVersion: "1.0.37";

@mixin font-face($selector, $name) {
  @font-face {
    font-display: auto;
    font-family: $name;
    src:
      url("/assets/main-fonts/#{$selector}.woff?version=#{$fontVersion}") format("woff"),
      url("/assets/main-fonts/#{$selector}.ttf?version=#{$fontVersion}") format("truetype");
    unicode-range: U+000-5FF;
  }
}
